import { Link } from "gatsby"
import React, { FunctionComponent, useEffect, useState } from "react"

const topThreshold = 10 // px
const animationDuration = "duration-700" // ms

type HeaderProps = {
  isFrontpage: boolean
  height: number
}

const Header: FunctionComponent<HeaderProps> = ({ isFrontpage, height }) => {
  const [isTop, setIsTop] = useState(isFrontpage)

  useEffect(() => {
    const onScroll = (e: any) => {
      const scrollTop = e.target.documentElement.scrollTop
      setIsTop(scrollTop < topThreshold)
    }

    if (isFrontpage) {
      window.addEventListener("scroll", onScroll)

      return () => window.removeEventListener("scroll", onScroll)
    }
  }, [isFrontpage, isTop])

  return (
    <nav
      className={`fixed z-10 top-0 w-full bg-sunyellow ${
        isTop ? "h-0" : `h-${height}`
      } transition-height ${animationDuration} shadow-xl`}
    >
      <Link to="/">
        {/* This is the variant of the logo that appears on the front page */}
        <img
          className={`absolute transition-transform transition-opacity ${animationDuration} transform origin-center ${
            isTop
              ? "front-page-logo translate-y-8 md:translate-y-0 opacity-100"
              : `w-${height} h-${height} translate-x-0 translate-y-0 opacity-0`
          }`}
          src="/img/logo-with-text.png"
          alt="Visit Teisko"
        />

        {/* This is the variant of the logo that appears on the menu bar */}
        <img
          className={`absolute transition-transform transition-opacity ${animationDuration} transform origin-center ${
            isTop
              ? "front-page-logo translate-y-8 md:translate-y-0 opacity-0"
              : `w-${height} h-${height} translate-x-0 translate-y-0 opacity-100`
          }`}
          src="/img/logo-without-text.png"
          alt="Visit Teisko"
        />
      </Link>
    </nav>
  )
}

export default Header
