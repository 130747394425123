import { CategoriesForFilterQuery } from "../../graphql-types"
import {
  Activity,
  ActivityFromQuery,
  Profile,
  ProfileFromQuery,
} from "../types"
import { Lang, simpleLang } from "../utils/i18n"

type CategoryResult = CategoriesForFilterQuery

export function getCategoriesFromQueryResult(
  categoryResult: CategoryResult,
  lang: Lang
) {
  const activities: Activity[] =
    categoryResult.prismic.allActivitys.edges
      ?.filter(
        (edge: ActivityFromQuery) => simpleLang(edge!.node._meta.lang) === lang
      )
      .map((edge: ActivityFromQuery) => ({
        id: edge!.node._meta.id,
        uid: edge!.node._meta.uid!,
        name: edge!.node.name[0].text,
        iconName: edge!.node.icon!,
      })) || []

  const profiles: Profile[] =
    categoryResult.prismic.allTravellerprofiles.edges
      ?.filter(
        (edge: ProfileFromQuery) => simpleLang(edge!.node._meta.lang) === lang
      )
      .map((edge: ProfileFromQuery) => ({
        id: edge!.node._meta.id,
        uid: edge!.node._meta.uid!,
        name: edge!.node.name[0].text,
        iconName: edge!.node.icon!,
      })) || []

  return { activities, profiles }
}
