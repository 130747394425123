import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"
import { graphql, Link, useStaticQuery } from "gatsby"
import { CategoriesForFooterQuery } from "../../graphql-types"
import { getCategoriesFromQueryResult } from "../queries/categories"
import { PageFromQuery } from "../types"
import i18n, { Lang, simpleLang } from "../utils/i18n"

type FooterProps = {
  lang: Lang
}

export const categoryQuery = graphql`
  query CategoriesForFooter {
    prismic {
      allActivitys(first: 100) {
        edges {
          node {
            name
            _meta {
              id
              uid
              lang
            }
          }
        }
      }
      allTravellerprofiles(first: 100) {
        edges {
          node {
            name
            _meta {
              id
              uid
              lang
            }
          }
        }
      }
      allPages(first: 100) {
        edges {
          node {
            title
            _meta {
              id
              uid
              lang
              type
            }
          }
        }
      }
    }
  }
`

export default function Footer(props: FooterProps) {
  const categoryResult = useStaticQuery(
    categoryQuery
  ) as CategoriesForFooterQuery

  const categories = getCategoriesFromQueryResult(categoryResult, props.lang)

  const pages =
    categoryResult.prismic.allPages.edges
      ?.filter(
        (edge: PageFromQuery) =>
          simpleLang(edge!.node._meta.lang) === props.lang
      )
      .map(edge => ({
        id: edge?.node._meta.id!,
        uid: edge?.node._meta.uid!,
        lang: edge?.node._meta.lang!,
        type: edge?.node._meta.type!,
        title: edge?.node.title[0].text as string,
      })) || []

  return (
    <footer className="w-full bg-darkpinegreen">
      <div className="container mx-auto p-4 text-white flex flex-row flex-wrap justify-center">
        <div className="flex-1 m-2">
          <h2>Visit Teisko</h2>
          Teiskon matkailu- ja hyvinvointipalveluiden tuki ry
          <br />
          c/o Heikki Toijala
          <br />
          <a href="mailto:info@visitteisko.fi">info@visitteisko.fi</a>
          <br />
          <a href="tel:+358400103396">+358 40 010 3396</a>
        </div>

        <div className="flex-1 m-2">
          <h3 className="whitespace-no-wrap">
            {i18n[props.lang].general.aboutService}
          </h3>
          <ul>
            {pages.map(page => (
              <Link key={`activity-${page.uid}`} to={linkResolver(page)}>
                <li>{page.title}</li>
              </Link>
            ))}
          </ul>
        </div>

        <div className="flex-1 m-2">
          <h3 className="whitespace-no-wrap">{i18n[props.lang].filter.what}</h3>
          <ul>
            {categories.activities.map(activity => (
              <Link
                key={`activity-${activity.id}`}
                to={`/${props.lang}/${activity.uid}`}
              >
                <li>{activity.name}</li>
              </Link>
            ))}
          </ul>
        </div>

        <div className="flex-1 m-2">
          <h3 className="whitespace-no-wrap">
            {i18n[props.lang].filter.withWhom}
          </h3>
          <ul>
            {categories.profiles.map(profile => (
              <Link
                key={`profile-${profile.id}`}
                to={`/${props.lang}/${profile.uid}`}
              >
                <li>{profile.name}</li>
              </Link>
            ))}
          </ul>
        </div>

        <div className="flex-1 m-2">
          <img src="/img/eu.jpg" className="mt-2" style={{ width: "20rem" }} />
        </div>
      </div>
    </footer>
  )
}
