import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import { Lang } from "../utils/i18n"
import Footer from "./Footer"
import Header from "./Header"

// TODO: Change
const defaultDescription =
  "Hei teiskolainen matkailu- tai hyvinvointipalvelun tuottaja. Jos haluat lisää näkyvyttä yrityksellesi ja puhtia liiketoimintaan niin jätä meille yhteystietosi."

// ___gatsby, gatsby-focus-wrapper
// py-12
// h-12
// w-12
const headerHeight = 12 // Tailwind units

type LayoutProps = {
  isFrontpage?: boolean
  children: any
  lang: Lang

  title?: string
  description?: string
  imageUrl?: string
}

const Layout: FunctionComponent<LayoutProps> = ({
  isFrontpage = false,
  lang,
  children,
  title,
  description,
  imageUrl,
}) => {
  const theTitle = `Visit Teisko${title ? ` – ${title}` : ""}`
  const theDescription = description ? description : defaultDescription
  const theImage = imageUrl ? imageUrl : "/img/logo-with-text.png"

  return (
    <div
      className={`min-h-full flex flex-col ${
        isFrontpage ? "bg-sunyellow" : "bg-white"
      }`}
    >
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#F7CB58" />
        <meta name="theme-color" content="#F7CB58" />

        <meta name="og:type" content="website" />
        <meta name="og:description" content={theDescription} />
        <meta name="og:title" content={theTitle} />
        <meta name="og:image" content={theImage} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={theTitle} />
        <meta name="twitter:description" content={theDescription} />
        <meta name="twitter:image" content={theImage} />

        <meta name="description" content={theDescription} />
        <title>{theTitle}</title>
      </Helmet>
      <Header isFrontpage={isFrontpage} height={headerHeight} />
      <div className={`flex-1 ${isFrontpage ? "" : `py-${headerHeight}`}`}>
        <div className={`${isFrontpage ? "" : "flex flex-row justify-center"}`}>
          <div className={`${isFrontpage ? "" : "container max-w-screen-lg"}`}>
            {children}
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  )
}

export default Layout
